<script>
/* eslint-disable no-unused-vars */
import { h } from 'vue'
import * as Vue from 'vue/dist/vue.esm-bundler'
export default {
  name: 'TableExtend',
  props: ['dom'],
  render () {
    if (this.dom) {
      if (this.dom.__v_isVNode) {
        return this.dom
      } else {
        return h(this.dom)
      }
    } else {
      return h('span')
    }
  }
}
</script>
