<template>
<div class="tableList">
  <div class="table">
    <el-table ref="table" :data="tableData" :row-key="getRowKeys" v-bind="$attrs" border @select="handleSelection" @selection-change="handleSelectionChange" stripe style="width: 100%">
      <el-table-column :type="item.selectType" :align="item.align" :label-class-name="item.fixed?item.labelClassName || 'isActive':undefined" :fixed="item.fixed" :reserve-selection="item.reserveSelection" :selectable="item.selectable" :show-overflow-tooltip="true" :key="JSON.stringify(item)" v-for="(item) in tableField" :[takeWidth(item.width)]="item.width" :prop="item.code" :label="item.label">
        <template #default="scope" v-if="item.type!='input'">
          <div v-if="item.type=='action'">
            <template :key="key" v-for="(actionItem,key) in item.actionData">
              <el-button
                v-if="!actionItem.stateField ? true :
                  actionItem.stateSymbol == '=' ? scope.row[actionItem.stateField] == actionItem.stateValue :
                  actionItem.stateSymbol == '==' ? scope.row[actionItem.stateField] === actionItem.stateValue :
                  actionItem.stateSymbol == '!=' ? scope.row[actionItem.stateField] != actionItem.stateValue :
                  actionItem.stateSymbol == '!==' ? scope.row[actionItem.stateField] !== actionItem.stateValue :
                  scope.row[actionItem.stateField] != actionItem.stateValue"
                size="mini"
                type="text"
                @click="handleClick(scope.$index, scope.row,actionItem)">
                  <span v-html="actionItem.actionLabel"></span>
                </el-button>
              </template>
          </div>
          <div v-if="item.type=='function'" v-html="item.handle(scope.$index, scope.row)"></div>
          <div v-if="item.type=='vnode'">
              <table-extend v-bind="$attrs" :dom='lineItem(item,scope)'></table-extend>
          </div>
          <div v-if="item.type=='amount'">{{amount (scope.row[item.code])}}</div>
          <div v-if="item.type=='quantity'">{{quantity (scope.row[item.code])}}</div>
        </template>
      </el-table-column>
  </el-table>
   <div class="tablepage" v-if="paginationData&&tableData&&tableData.length>0">
     <el-pagination
      @current-change="currentChange"
      :page-size="paginationData.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="paginationData.total"
      :currentPage="pageNum">
     </el-pagination>
   </div>
  </div>
</div>
</template>
<script>
import TableExtend from './TableExtend.vue'
export default {
  components: { TableExtend },
  name: 'GlobalTable',
  props: ['tableField', 'tableData', 'paginationData', 'pageNum'],
  data: function () {
    return {
      selectData: [],
      currentPage: this.pageNum || 1
    }
  },
  methods: {
    clearSelection() {
        this.$refs.table.clearSelection()
    },
    amount (data) {
      data = parseFloat(data) ? parseFloat(data).toLocaleString('zh', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : data
      return data
    },
    quantity (data) {
      return parseFloat(data) ? parseFloat(data).toFixed(3) : data
    },
    takeWidth (data) {
      return data ? 'min-width' : 'noWidth'
    },
    getRowKeys: function (row) {
      return row.id
    },
    lineItem: function (item, scope) {
      return item.handle.call(this, scope)
    },
    handleClick: function (index, data, actiondata) {
      this.$emit('tableAction', index, data, actiondata)
    },
    currentChange: function (data) {
      this.$emit('pageChange', data)
    },
    handleSelectionChange (val) {
      this.selectData = val
      this.$emit('selectData', val)
    },
    handleSelection (selection, row) {
      this.$emit('SelectAction', selection, row, this)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
</style>
